<template>
  <article class="ImageContent">
    <section class="main-container">
      <div class="main-container-inner">
        <slot name="content" />
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: 'OneColumn',

  props: {
    hasGreyBg: {
      type: Boolean,
      default: false
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports.scss';
@import 'vue-select/src/scss/vue-select.scss';
@import '../styles/_steps.scss';

.ImageContent {
  display: flex;
  flex-direction: column;
  background-color: $white;

  @include media('>=sm') {
    flex-direction: row;
  }

  .main-container {
    position: relative;
    background-image: url('../assets/images/background.jpg');
    background-size: cover;
    background-position: right center;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    text-align: center;
    width: 100%;
    min-height: 100vh;

    @include media('>=sm') {
      position: relative;
      /* leave room for the footer */
      min-height: calc(100vh - 61px);
    }

    padding-top: 92px;

    @include media('>=sm') {
      padding-top: 105px;
    }
    @include media('>=md') {
      padding-top: 119px;
    }
  }

  .main-container-inner {
    padding: 50px 5%;
    width: 100%;
    box-sizing: border-box;
    text-align: center;

    .Headline {
      margin-bottom: 10px;
    }

    figure {
      position: relative;
      max-width: 300px;
      margin: 0;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 5px;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 50%;
        box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
      }

      @include media('>=sm') {
        max-width: initial;
      }
    }

    img {
      width: 100%;
    }

    p {
      text-align: left;
      color: $color-grey-700;
      margin: 2em -2em 0;
    }

    ul {
      list-style: none;
      margin: 1em 0 0;
      padding: 0;
    }

    li {
      position: relative;
      padding-left: 1em;
      line-height: 1.4;
      margin-bottom: 0.5em;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: .7em;
        left: 0;
        width: 3px;
        height: 3px;
        background-color: $color-grey-700;
        border-radius: 3px;
        transform: translate3d(0, -50%, 0);
      }
    }

    @include media('>=sm') {
      padding: 50 25%;
    }
  }
}

.Content {
  margin: 200px auto 50px;
  width: 100%;

  @include media('>=sm') {
    width: 420px;
  }

  @include media('>=md') {
    margin-bottom: 200px;
  }
}

.Logo {
  position: absolute;
  top: 100px;
  left: 30px;

  img {
    height: 40px;
  }

  @include media('>=sm') {
    left: initial;
    right: 100px;
  }
}

</style>
